<template>
  <div>
    <div class="wrapper">
      <!-- section dashboard -->
      <section id="dashboard-owner" class="section-duke section-dark jpadding jpadding-20 duke-style">
        <div class="jtitle jtitle-80 text-center">
          <h1>PRESS KITS</h1>
          <!-- <div class="jsubtitle">
            <p id="p-text">
              <small>Download.</small>
            </p>
          </div> -->
        </div>

        <div class="grid-3">
          <div class="jcard-duke" @click="openPdf('/assets/press-kits/DUKE_FIRST_EDITION_PRESS_RELEASE_EN.pdf')">
            <i class="fal fa-file-pdf fa-3x mb-3"></i>
            <h5>PRESS RELEASE</h5>
            <p class="m-0"><small>FIRST EDITION</small></p>
            <h5 class="mt-3"><country-flag country="fr" size="small" /></h5>
          </div>
          <div class="jcard-duke" @click="openPdf('/assets/press-kits/DUKE_FIRST_EDITION_PRESS_RELEASE_FR.pdf')">
            <i class="fal fa-file-pdf fa-3x mb-3"></i>
            <h5>PRESS RELEASE</h5>
            <p class="m-0"><small>FIRST EDITION</small></p>
            <h5 class="mt-3"><country-flag country="gb" size="small" /></h5>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const auth = firebase.auth;
const db = firebase.db;

export default {
  name: "DashboardOwner",
  components: {
    CountryFlag
  },
  data() {
    return {
      //userId: firebase.auth().currentUser.uid,
    };
  },
  methods: {
    //open pdf file in new tab
    openPdf(url) {
      console.log("Opening PDF...");
      window.open(url, "_blank"); //to open in new tab
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      },
    },
  },
  beforeCreate() {
    //load user
    var userDocRef = db.collection(C.COLLECTION.USERS).doc(auth.currentUser.uid);
    userDocRef
      .get()
      .then((userDoc) => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function (error) {
        console.log("Error getting USER document:", error);
      });
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/platform.scss";
</style>
